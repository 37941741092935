.menu-container.mat-menu-panel {
    max-width: initial !important
}

.filter-menu-container.mat-menu-panel {
    max-width: initial !important
}

.account-menu {}

.menu-filter-button {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.menu-filter-button.mdc-button {
    padding: 0px;
    min-width: auto;
    background-color: whitesmoke;
    font-weight: 400;
}

.menu-icon.mat-mdc-button {
    margin: 0px;
}



.block-link {
    display: block;
    text-decoration: none;
}




.account-menu-panel {
    display: block;
    background: rgb(255, 255, 255);
    border-radius: 3px;
    // min-width: 290px;
    border: 1px solid rgb(203, 214, 226) !important;
    padding: 0px !important;
    margin: 0px !important;
}

.menu-filter-container {
    display: block;
    position: relative;
    text-decoration: none;
    list-style-type: none !important;
    line-height: 1.5rem !important;
    color: rgb(51, 71, 91) !important;
}


.menu-filter-section-full-width,
.menu-filter-section {
    padding: 10px;
    border-bottom: 1px solid rgb(203, 214, 226) !important;

    &:last-child {
        border-bottom: 0px;
    }
}

.menu-filter-section-full-width {
    padding: 0px;
}

.menu-filter-item-full-width,
.menu-filter-item {
    padding: 10px;
    border-bottom: 1px solid rgb(203, 214, 226) !important;

    &:last-child {
        border-bottom: 0px;
    }
}

div.hover-item {
    &:hover {
        background-color: rgb(245, 245, 245);
        cursor: pointer;
    }
}

.menu-filter-item-full-width-margins {
    margin: 10px 20px;
}

.menu-filter-item-full-width {
    padding: 10px 20px;
}

.menu-filter-limit-height {
    max-height: 300px;
    overflow-y: auto;
}


.menu-section {
    display: block;
    position: relative;
    text-decoration: none;
    font-weight: 500;
    // min-height: 80px;
    list-style-type: none !important;
    line-height: 1.5rem !important;
    color: rgb(51, 71, 91) !important;
    border-bottom: 1px solid rgb(203, 214, 226) !important;
    padding: 8px 16px !important;
}

.menu-section img {
    width: 48px;
    height: 48px;
    left: 16px;
    top: 16px;
    position: absolute;
    border-radius: 50%;
    margin: 0px !important;
}

.menu-section .user-pref-text-container {
    padding-left: 60px !important;
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.menu-section .user-pref-text-container .user-pref-name {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    color: rgb(51, 71, 91) !important;
    font-weight: 500 !important;
    font-size: 16px !important;
}


.menu-section .org-name,
.menu-section .user-subscription-level,
.menu-section .user-pref-customer-id,
.menu-section .user-pref-text-container .user-pref-email {
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin-top: 2px !important;
    color: rgb(51, 71, 91) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
}

.menu-section .user-pref-text-container .user-pref-description {
    white-space: pre-wrap;
    font-weight: 500;
    margin-top: 4px !important;
    color: rgb(0, 145, 174) !important;
    font-size: 14px !important;
    line-height: 20px !important;
}

.menu-item-first {}

.menu-item-last {}

.menu-item {
    margin: 4px 0px;
}

.menu-inline-flex {
    justify-content: space-between;
    display: flex;
}

.account-menu-organization-header {
    color: rgb(124, 152, 182);
    font-size: 14px;
    font-weight: 500;
    white-space: pre-wrap;
    overflow-wrap: break-word;
}

.account-menu-organization-id,
.account-menu-organization-name {
    font-size: 14px;
    color: rgb(51, 71, 91);
    white-space: pre-wrap;
    overflow-wrap: break-word;
    text-decoration: none;
}