.transparent-button {
    border: inherit;
    background-color: inherit;
}

.action-buttons {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
}

.action-button-mobile {
    padding-top: 15px;
}

.business-upgrade-button,
.business-upgrade-button.mat-mdc-button {
    background-color: #F26D3F;
    color: #fff;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
    font-weight: 500;
    font-size: 16px !important;
    border-radius: 3px;
    border-style: none;
    // border-width: 1px;
}

.login-button {
    width: initial;
}

.login-help .login-button {
    width: 180px;
}

.bulk-label .passpack-button {
    width: initial;
}

.invitation-box .passpack-button,
.new-password-box .passpack-button {
    // width: 220px;
    padding-top: 6px;
    padding-bottom: 6px;
}

.mfa-buttons .passpack-button,
.packing-key-buttons .passpack-button,
.login-help .login-button,
.uplogin .login-button,
.ssologin .sso-login-button,
.singlessologin .sso-login-button {
    width: 220px;
    padding-top: 6px;
    padding-bottom: 6px;
}


.quick-start-button.mat-mdc-button {
    background-color: #F26D3F;
    padding-left: 16px !important;
    padding-right: 16px !important;
}

// .btn,
// .btn:hover,
.passpack-button {
    // color: #fff;
    // background-color: #001c53;
    background-color: #fff;
    border: 1px solid;

    font-size: 14px;
    width: 100%;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
    // font-weight: 500;
    white-space: nowrap;

}

.passpack-button.wrap-text {
    white-space: normal;

}

.recently-deleteted-button.mat-mdc-button {
    padding: 0px;
    min-width: auto;
    font-weight: 400;
}

.setting-button,
.label-list-action-button,
.passpack-button,
.team-action-button,
.license-manager-button,
.no-padding-button,
.password-list-action-button {
    padding-top: 0px;
    padding-bottom: 0px;
}

button:not(:disabled):hover.passpack-button {
    background-color: #fff;
    border: 1 px solid;
    border-color: #001c53;
    color: #001c53;
}

.delete-team-button,
.delete-label-button,
.delete-password-button {
    background-color: red;
    font-size: 14px;
    color: #fff;
    text-transform: uppercase;
    text-rendering: optimizeLegibility;
    font-weight: 500;
    ;
}

button:not(:disabled):hover.delete-label-button,
button:not(:disabled):hover.delete-team-button,
button:not(:disabled):hover.delete-password-button {
    background-color: #fff;
    border: 1 px solid;
    border-color: #001c53;
    color: red;
}




.command-box {
    border-color: rgb(120, 57, 238);
    background-color: rgb(244, 243, 255);
    color: rgb(120, 57, 238);
    font-size: 12px;
    line-height: 10px;
    font-weight: bold;
    letter-spacing: 0;
    border: 1px solid;
    border-radius: 3px;
    padding: 5px 12px;
    margin-bottom: 2px;
    margin-top: 2px;
    white-space: nowrap;
    text-transform: uppercase;
    cursor: pointer;
    /* a:link { text-decoration: none; }
    a:visited { text-decoration: none; }
    a:hover { text-decoration: none; }
    a:active { text-decoration: none; } */
}

.command-button {
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    font-family: var(--uicomponents-font-family, "Lexend Deca", Helvetica, Arial, sans-serif);
    font-weight: 400;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all .15s ease-out;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
}

.command-button-quick {

    border-width: 0px;
    background-color: transparent;
}

.action-button {
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    font-family: var(--uicomponents-font-family, "Lexend Deca", Helvetica, Arial, sans-serif);
    font-weight: 400;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all .15s ease-out;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    background-color: #eaf0f6;
    border-color: #cbd6e2;
    color: #506e91;
}

.button-no-padding {
    padding: 0px !important;
}

.quick-action-button {
    color: #506e91;
    border-width: 0px;
    background-color: transparent;
}

.quick-copy-button {
    text-align: center;
    text-overflow: clip;
    // font-size: 32px;
    background-color: #eaf0f6;
    border-color: #cbd6e2;
    color: #506e91;

}

.quick-copy-action-button {
    color: #506e91;
    border-color: #506e91;
    border-width: 0px;
    background-color: transparent;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    font-family: var(--uicomponents-font-family, "Lexend Deca", Helvetica, Arial, sans-serif);
    font-weight: 400;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all .15s ease-out;
    display: flex;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    white-space: nowrap;
    padding: 3px;
}

// .mat-mdc-slide-toggle .mdc-form-field .mdc-switch {

//     --mdc-selected-handle-color: rgb(255, 0, 21);
//     --mdc-switch-selected-track-color: green;
//     --mdc-switch-unselected-track-color: gray;

//     --mdc-switch-selected-icon-color: rgb(255, 0, 21);
//     --mdc-switch-unlected-icon-color: black;
// }