.settings-nav .mat-mdc-tab .mdc-tab__text-label {
    font-size: 18px;
    ;
}

.settings-right-container {
    // margin-top: 20px;
    // padding-left: 15px;
    background-color: white;
    padding-top: 5px;
    padding-right: 10px;
    padding-left: 10px;
}

.settings-full-width {
    width: -webkit-fill-available;
}

.settings-header {
    // padding-bottom: 25px;
}

.settings-header h1 {
    padding-top: 10px;
    font-size: 22px;
    // padding-bottom: 10px;
}

.settings-header h2 {
    padding-top: 10px;
    font-size: 16px;
    // padding-bottom: 10px;
}

.settings-intro-paragraph {
    padding-bottom: 15px;
}

.settings-container {
    max-width: 800px;
}

.settings-items-container {
    width: -webkit-fill-available;
}

.settings-items-container {
    &>* {
        border-bottom: 1px solid rgb(203, 214, 226) !important;

        &:last-child {
            border-bottom: 10px;
        }
    }
}


.settings-raised-box {
    background-color: white;
    padding: 10px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin-bottom: 25px;
}


.settings-item {
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    width: 100%;
}

.settings-item-info {
    display: inline-flex;
    flex-direction: row;
    // justify-content: center;
}

.settings-item-label {
    display: flex;
    font-size: 18px;
    font-weight: bold;
}

.settings-item-description {
    display: flex;
    // font-size: 18px;
    // font-weight: bold;
    max-width: 400px;
    padding-left: 15px;
}



.overridden-setting {
    background-color: lightgrey;
    padding: 10px;
}

.overridden-setting .override-value {
    font-weight: bold;
}

.setting-item {}

.setting-item:hover {
    background-color: #f1f1f1
}



.setting-item .inner-setting-wrapper {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
    width: 100%;
    flex-direction: row
}

.setting-item .inner-setting-wrapper .key-value-wrapper {
    // display: flex;
    // flex-direction: row;
    padding-bottom: 8px;
    width: 100%;
}

@media(min-width:768px) {
    .setting-item .inner-setting-wrapper .key-value-wrapper {
        display: flex;
        flex-direction: row;
    }

    .setting-item .inner-setting-wrapper .key-value-wrapper .description {
        padding-left: 15px;
    }
}

@media(max-width:768px) {
    .setting-item .inner-setting-wrapper .key-value-wrapper {}

    .setting-item .inner-setting-wrapper .key-value-wrapper .description {
        padding-top: 15px;
        padding-left: 10px;
    }
}

.setting-item .inner-setting-wrapper .key-value-wrapper .title {
    flex-basis: 150px;
    font-weight: 500;
    font-size: 16px;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0
}



.setting-item .inner-setting-wrapper .key-value-wrapper .checked {
    flex-basis: 20px;
    padding-right: 10px;
    align-content: center;
    font-weight: 500;
    font-size: 16px;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0
}

.setting-item .inner-setting-wrapper .key-value-wrapper .value-wrapper {
    white-space: pre-line;
    display: flex;
    flex-direction: column
}

.setting-item .inner-setting-wrapper .description {
    // padding-top: 10px;
    // font-size: 12px
}

.setting-item .inner-setting-wrapper .settings-item-value {
    display: flex;
    padding-left: 20px;

}

.registered-api-key-header {
    width: 100%;
    border-bottom: 2px solid rgb(203, 214, 226) !important;
    padding-top: 10px;
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 16px;
}

.registered-api-key-row {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.registered-api-key-container {
    &>* {
        border-bottom: 1px solid rgb(203, 214, 226) !important;

        &:last-child {
            border-bottom: 0px;
        }
    }
}