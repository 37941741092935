// Link styles
.link-no-decoration,
.link-no-decoration a {
    text-decoration: none;
    color: initial;
}

.link-no-decoration a:hover {
    cursor: pointer;
    text-decoration: underline;
    color: initial;
}

.clear-action-link {
    color: #155be7;
    text-decoration: underline;
    cursor: pointer;
}

.link-white,
.link-white a,
.link-white a:hover {
    color: white;
}


.link-blue,
.link-blue a,
.link-blue a:hover {
    color: #001c53;
}

.action-link-icon.mat-icon {
    font-size: 22px;
    height: 22px;
    width: 22px;
    vertical-align: baseline;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}