.cdk-drag-preview {
    background: lightblue;
    border: 1px dashed black;
    border-radius: 2px;
    margin: 0;
    padding: 0;
}

/* Animate items as they're being sorted. */
.cdk-drop-list-dragging .cdk-drag {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* Animate an item that has been dropped. */
.cdk-drag-animating {
    transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}