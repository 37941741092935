.detail-container {
    display: flex;
    align-self: stretch !important;
    align-items: stretch !important;
    flex-grow: 1;
    width: 100%;
}

.mobile-detail-container {
    width: 100%;
    padding: 5px;
}

.detail-highlight-container {
    padding: 10px;
    border-bottom: 1px solid #dfe3eb;
    padding-bottom: 10px;
}

.detail-left {
    max-width: 300px;
    min-width: 300px;
    overflow-y: auto !important;
    background-color: #fff;
    border-right: 1px solid #dfe3eb;
}

.detail-center {
    width: 100%;
    overflow-y: auto !important;
    display: flex;
    align-self: stretch !important;
    flex-direction: column;
    padding: 15px;
}

.detail-right {
    max-width: 300px;
    min-width: 300px;
    overflow-y: auto !important;
    background-color: #fff;
    border-left: 1px solid #dfe3eb;
}

.detail-right-large {
    max-width: 500px;
    min-width: 300px;
    overflow-y: auto !important;
    background-color: #fff;
    border-left: 1px solid #dfe3eb;
}

.border-left-2px {
    border-left: 2px solid #001c53;
}

.not-found {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    margin-top: 50px;
}

.team-side-component-title,
.password-side-component-title,
.side-component-title {}

.detail-center-container {
    padding-top: 15px;
}

.detail-center-container-label {
    font-size: 18px;
    padding-bottom: 10px;
    color: #516f90;
}

.nav {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-direction: row;

}

.nav a {
    display: inline-flex;
    font-weight: 500;
}

.quick-details-body {
    padding-top: 15px;
}

.quick-details-item {
    margin-bottom: 15px;
}

.quick-details-label {
    font-size: 12px;
    color: #516f90;
}

.quick-details-value {
    font-size: 14px;
    color: #33475b;
}