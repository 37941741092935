.ng-valid[required] input,
.ng-valid.required input {
    border-left: 5px solid #42A948;
}

.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
    padding-bottom: 10px;
}

.label-field {
    div.mdc-text-field {
        padding-left: 0px !important;
    }
}

.ng-invalid[required] input,
.ng-invalid.required input {
    border-left: 5px solid #a94442;
}

label {
    display: block;
    margin-top: 10px;
}

.no-border {
    border: none;

}

.form-label {
    font-weight: 500;
}

.form-control {
    font-size: 14px;
}

.form-header {
    text-align: center;
    font-size: 36px;
    margin-bottom: 15px;
    // white-space: nowrap;
}

.form-sub-header {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.confirm-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.register-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}



.invitation-box {
    margin-left: auto;
    margin-right: auto;
    padding: 40px 40px;
}

@media(max-width:768px) {

    .invite-register-sso {
        padding-top: 25px;
    }

    .invitation-box.form-box {
        padding: 20px 20px;
    }

    .new-password-box.form-box,
    .invite-small-box.form-box,
    .email-change-box.form-box,
    .locking-box.form-box,
    .logging-out-box.form-box,
    .verify-email-box.form-box,
    .packing-key-box.form-box,
    .mfa-box.form-box,
    .login-box.form-box {
        max-width: 370px !important;
        padding: 20px 20px;
    }

    .login-container,
    .register-container {
        margin-top: 5px;
    }
}

@media(min-width:768px) {

    .new-password-box.form-box,
    .invite-small-box.form-box,
    .email-change-box.form-box,
    .locking-box.form-box,
    .logging-out-box.form-box,
    .verify-email-box.form-box,
    .packing-key-box.form-box,
    .mfa-box.form-box {
        max-width: 380px !important;
        padding: 20px 20px;
    }

    .login-box.form-box {
        max-width: 700px !important;
        padding: 40px 40px;
    }

    .login-container,
    .register-container {
        margin-top: 20px;
    }
}







.form-box {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    // -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    // -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    // box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);

    // border-radius: 5px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.input-group {
    margin-bottom: 1rem !important;
}

.invalid-feedback {
    display: block !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}

.valid-feedback {
    display: block !important;
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: green;
}



.auth-google-or {
    flex-grow: 0;
    flex-shrink: 0;
    text-align: center;
    color: #001c53;
    font-size: 18px;
    font-weight: normal;
    padding: 5px;
    justify-content: center;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        width: 120px;
        height: 1px;
        background: rgba(6, 41, 66, 0.2);
        margin-right: 40px;
        padding-left: 0px;
    }

    &::after {
        content: '';
        width: 120px;
        height: 1px;
        background: rgba(6, 41, 66, 0.2);
        margin-left: 40px;
        padding-right: 0px;
    }
}