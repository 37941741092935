/** Header **/
.active-header-link {
    color: yellow !important;
}



.mat-mdc-button {
    height: 20px;
    font-size: 14px;
    color: #fff;
    font-stretch: initial;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: 500;
    letter-spacing: normal;
    padding-left: 0px;
    padding-right: 16px;
    ;
}

.menu-icon {
    margin-left: 4px !important;
}

.mat-mdc-button .mdc-button__label+.mat-icon {
    margin-left: 4px;
    margin-right: 0;
}



.mat-mdc-menu-panel {
    // background-color: aqua !important;
}

.mat-mdc-menu-content {
    // background-color: blue !important;
}

.mat-mdc-menu-item {
    // background-color: blue;
}

.page-header {
    line-height: 1.5rem;
}

.global-toolbar {
    display: flex;
    padding: 0px 12px 0px 12px !important;
    color: white;
    font-size: 13px;
    justify-content: space-between;
}

.global-toolbar-left {
    flex-direction: row;
    align-items: center;
    vertical-align: baseline;
    transition: none 0s ease 0s;
    margin: 0px;
    padding: 0px;
    border: 0px;
    display: flex;
}

.global-toolbar-items-container {
    flex-direction: row;
    align-items: center;
    vertical-align: baseline;
    transition: none 0s ease 0s;
    margin: 0px;
    padding: 0px;
    border: 0px;
}

.global-toolbar-items {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline-start: 0px;
    height: 100%;
    margin: 0px;
    padding: 0px;
    border: 0px;
}

.global-toolbar-item {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100% !important;
    padding: 0px 2px !important;

    -webkit-font-smoothing: antialiased;
    font-family: var(--uicomponents-font-family, "Lexend Deca", Helvetica, Arial, sans-serif);
    font-size: 13px;
    font-weight: 500;
    // color: rgb(51, 71, 91);
    line-height: 1.5rem;
    margin: 0px;
    padding: 0px;
    border: 0px;
    vertical-align: baseline;
    transition: none 0s ease 0s;
    text-shadow: transparent 0px 0px 1px;
    box-sizing: border-box;
}

.global-toolbar-accounts {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 100% !important;
    padding: 0px 12px !important;
}

.global-toolbar-accounts span {
    margin: 0px 5px;
}

.global-toolbar-item img {
    border-radius: 50%;
    height: 18px;
    width: auto;
}

.global-toolbar-link {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

.header-nav-authenticated {
    display: flex !important;
}


.passpack-logo-nav-bar {
    height: 12px;
}



.header-menu-link a {
    text-decoration: none;
    font-size: 14px;
}

.header-menu-link a:hover {
    cursor: pointer;
    text-decoration: underline;
}



.menu-link-security a {
    color: salmon;
}

.menu-link-security a:hover {
    color: red;
}