.label-chip {
    // display: inline-block;
    // padding: 0.25em 0.4em;
    // font-size: 75%;
    // font-weight: 700;
    // line-height: 1;
    // text-align: center;
    // white-space: nowrap;
    // vertical-align: baseline;
    // border-radius: 0.25rem;
    //   background-color: #007bff;
    margin-right: 5px;
}

.label-chips {
    display: flex;
    flex-wrap: wrap;
    // margin-top: 5px;
}

.label-small-chip {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    //   background-color: #007bff;
}

.label-table {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.label-button {
    width: initial;
}

.label-inline-edit {
    display: flex;
    padding-top: 5px;
    padding-bottom: 5px;
}

.label-update-color {
    min-width: 100px;
}