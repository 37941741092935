.icon-color-red {
    color: #F44336;

}

.icon-color-red-50 {
    color: #FFEBEE;

}

.icon-color-red-100 {
    color: #FFCDD2;

}

.icon-color-red-200 {
    color: #EF9A9A;

}

.icon-color-red-300 {
    color: #E57373;

}

.icon-color-red-400 {
    color: #EF5350;

}

.icon-color-red-500 {
    color: #F44336;

}

.icon-color-red-600 {
    color: #E53935;

}

.icon-color-red-700 {
    color: #D32F2F;

}

.icon-color-red-800 {
    color: #C62828;

}

.icon-color-red-900 {
    color: #B71C1C;

}

.icon-color-red-a100 {
    color: #FF8A80;

}

.icon-color-red-a200 {
    color: #FF5252;

}

.icon-color-red-a400 {
    color: #FF1744;

}

.icon-color-red-a700 {
    color: #D50000;

}

.icon-color-pink {
    color: #E91E63;

}

.icon-color-pink-50 {
    color: #FCE4EC;

}

.icon-color-pink-100 {
    color: #F8BBD0;

}

.icon-color-pink-200 {
    color: #F48FB1;

}

.icon-color-pink-300 {
    color: #F06292;

}

.icon-color-pink-400 {
    color: #EC407A;

}

.icon-color-pink-500 {
    color: #E91E63;

}

.icon-color-pink-600 {
    color: #D81B60;

}

.icon-color-pink-700 {
    color: #C2185B;

}

.icon-color-pink-800 {
    color: #AD1457;

}

.icon-color-pink-900 {
    color: #880E4F;

}

.icon-color-pink-a100 {
    color: #FF80AB;

}

.icon-color-pink-a200 {
    color: #FF4081;

}

.icon-color-pink-a400 {
    color: #F50057;

}

.icon-color-pink-a700 {
    color: #C51162;

}

.icon-color-purple {
    color: #9C27B0;

}

.icon-color-purple-50 {
    color: #F3E5F5;

}

.icon-color-purple-100 {
    color: #E1BEE7;

}

.icon-color-purple-200 {
    color: #CE93D8;

}

.icon-color-purple-300 {
    color: #BA68C8;

}

.icon-color-purple-400 {
    color: #AB47BC;

}

.icon-color-purple-500 {
    color: #9C27B0;

}

.icon-color-purple-600 {
    color: #8E24AA;

}

.icon-color-purple-700 {
    color: #7B1FA2;

}

.icon-color-purple-800 {
    color: #6A1B9A;

}

.icon-color-purple-900 {
    color: #4A148C;

}

.icon-color-purple-a100 {
    color: #EA80FC;

}

.icon-color-purple-a200 {
    color: #E040FB;

}

.icon-color-purple-a400 {
    color: #D500F9;

}

.icon-color-purple-a700 {
    color: #AA00FF;

}

.icon-color-deep-purple {
    color: #673AB7;

}

.icon-color-deep-purple-50 {
    color: #EDE7F6;

}

.icon-color-deep-purple-100 {
    color: #D1C4E9;

}

.icon-color-deep-purple-200 {
    color: #B39DDB;

}

.icon-color-deep-purple-300 {
    color: #9575CD;

}

.icon-color-deep-purple-400 {
    color: #7E57C2;

}

.icon-color-deep-purple-500 {
    color: #673AB7;

}

.icon-color-deep-purple-600 {
    color: #5E35B1;

}

.icon-color-deep-purple-700 {
    color: #512DA8;

}

.icon-color-deep-purple-800 {
    color: #4527A0;

}

.icon-color-deep-purple-900 {
    color: #311B92;

}

.icon-color-deep-purple-a100 {
    color: #B388FF;

}

.icon-color-deep-purple-a200 {
    color: #7C4DFF;

}

.icon-color-deep-purple-a400 {
    color: #651FFF;

}

.icon-color-deep-purple-a700 {
    color: #6200EA;

}


.icon-color-indigo {
    color: #3F51B5;

}

.icon-color-indigo-50 {
    color: #E8EAF6;

}

.icon-color-indigo-100 {
    color: #C5CAE9;

}

.icon-color-indigo-200 {
    color: #9FA8DA;

}

.icon-color-indigo-300 {
    color: #7986CB;

}

.icon-color-indigo-400 {
    color: #5C6BC0;

}

.icon-color-indigo-500 {
    color: #3F51B5;

}

.icon-color-indigo-600 {
    color: #3949AB;

}

.icon-color-indigo-700 {
    color: #303F9F;

}

.icon-color-indigo-800 {
    color: #283593;

}

.icon-color-indigo-900 {
    color: #1A237E;

}

.icon-color-indigo-a100 {
    color: #8C9EFF;

}

.icon-color-indigo-a200 {
    color: #536DFE;

}

.icon-color-indigo-a400 {
    color: #3D5AFE;

}

.icon-color-indigo-a700 {
    color: #304FFE;

}


.icon-color-blue {
    color: #2196F3;

}

.icon-color-blue-50 {
    color: #E3F2FD;

}

.icon-color-blue-100 {
    color: #BBDEFB;

}

.icon-color-blue-200 {
    color: #90CAF9;

}

.icon-color-blue-300 {
    color: #64B5F6;

}

.icon-color-blue-400 {
    color: #42A5F5;

}

.icon-color-blue-500 {
    color: #2196F3;

}

.icon-color-blue-600 {
    color: #1E88E5;

}

.icon-color-blue-700 {
    color: #1976D2;

}

.icon-color-blue-800 {
    color: #1565C0;

}

.icon-color-blue-900 {
    color: #0D47A1;

}

.icon-color-blue-a100 {
    color: #82B1FF;

}

.icon-color-blue-a200 {
    color: #448AFF;

}

.icon-color-blue-a400 {
    color: #2979FF;

}

.icon-color-blue-a700 {
    color: #2962FF;

}

.icon-color-light-blue {
    color: #03A9F4;

}

.icon-color-light-blue-50 {
    color: #E1F5FE;

}

.icon-color-light-blue-100 {
    color: #B3E5FC;

}

.icon-color-light-blue-200 {
    color: #81D4FA;

}

.icon-color-light-blue-300 {
    color: #4FC3F7;

}

.icon-color-light-blue-400 {
    color: #29B6F6;

}

.icon-color-light-blue-500 {
    color: #03A9F4;

}

.icon-color-light-blue-600 {
    color: #039BE5;

}

.icon-color-light-blue-700 {
    color: #0288D1;

}

.icon-color-light-blue-800 {
    color: #0277BD;

}

.icon-color-light-blue-900 {
    color: #01579B;

}

.icon-color-light-blue-a100 {
    color: #80D8FF;

}

.icon-color-light-blue-a200 {
    color: #40C4FF;

}

.icon-color-light-blue-a400 {
    color: #00B0FF;

}

.icon-color-light-blue-a700 {
    color: #0091EA;

}

.icon-color-cyan {
    color: #00BCD4;

}

.icon-color-cyan-50 {
    color: #E0F7FA;

}

.icon-color-cyan-100 {
    color: #B2EBF2;

}

.icon-color-cyan-200 {
    color: #80DEEA;

}

.icon-color-cyan-300 {
    color: #4DD0E1;

}

.icon-color-cyan-400 {
    color: #26C6DA;

}

.icon-color-cyan-500 {
    color: #00BCD4;

}

.icon-color-cyan-600 {
    color: #00ACC1;

}

.icon-color-cyan-700 {
    color: #0097A7;

}

.icon-color-cyan-800 {
    color: #00838F;

}

.icon-color-cyan-900 {
    color: #006064;

}

.icon-color-cyan-a100 {
    color: #84FFFF;

}

.icon-color-cyan-a200 {
    color: #18FFFF;

}

.icon-color-cyan-a400 {
    color: #00E5FF;

}

.icon-color-cyan-a700 {
    color: #00B8D4;

}


.icon-color-teal {
    color: #009688;

}

.icon-color-teal-50 {
    color: #E0F2F1;

}

.icon-color-teal-100 {
    color: #B2DFDB;

}

.icon-color-teal-200 {
    color: #80CBC4;

}

.icon-color-teal-300 {
    color: #4DB6AC;

}

.icon-color-teal-400 {
    color: #26A69A;

}

.icon-color-teal-500 {
    color: #009688;

}

.icon-color-teal-600 {
    color: #00897B;

}

.icon-color-teal-700 {
    color: #00796B;

}

.icon-color-teal-800 {
    color: #00695C;

}

.icon-color-teal-900 {
    color: #004D40;

}

.icon-color-teal-a100 {
    color: #A7FFEB;

}

.icon-color-teal-a200 {
    color: #64FFDA;

}

.icon-color-teal-a400 {
    color: #1DE9B6;

}

.icon-color-teal-a700 {
    color: #00BFA5;

}


.icon-color-green {
    color: #4CAF50;

}

.icon-color-green-50 {
    color: #E8F5E9;

}

.icon-color-green-100 {
    color: #C8E6C9;

}

.icon-color-green-200 {
    color: #A5D6A7;

}

.icon-color-green-300 {
    color: #81C784;

}

.icon-color-green-400 {
    color: #66BB6A;

}

.icon-color-green-500 {
    color: #4CAF50;

}

.icon-color-green-600 {
    color: #43A047;

}

.icon-color-green-700 {
    color: #388E3C;

}

.icon-color-green-800 {
    color: #2E7D32;

}

.icon-color-green-900 {
    color: #1B5E20;

}

.icon-color-green-a100 {
    color: #B9F6CA;

}

.icon-color-green-a200 {
    color: #69F0AE;

}

.icon-color-green-a400 {
    color: #00E676;

}

.icon-color-green-a700 {
    color: #00C853;

}


.icon-color-light-green {
    color: #8BC34A;

}

.icon-color-light-green-50 {
    color: #F1F8E9;

}

.icon-color-light-green-100 {
    color: #DCEDC8;

}

.icon-color-light-green-200 {
    color: #C5E1A5;

}

.icon-color-light-green-300 {
    color: #AED581;

}

.icon-color-light-green-400 {
    color: #9CCC65;

}

.icon-color-light-green-500 {
    color: #8BC34A;

}

.icon-color-light-green-600 {
    color: #7CB342;

}

.icon-color-light-green-700 {
    color: #689F38;

}

.icon-color-light-green-800 {
    color: #558B2F;

}

.icon-color-light-green-900 {
    color: #33691E;

}

.icon-color-light-green-a100 {
    color: #CCFF90;

}

.icon-color-light-green-a200 {
    color: #B2FF59;

}

.icon-color-light-green-a400 {
    color: #76FF03;

}

.icon-color-light-green-a700 {
    color: #64DD17;

}


.icon-color-lime {
    color: #CDDC39;

}

.icon-color-lime-50 {
    color: #F9FBE7;

}

.icon-color-lime-100 {
    color: #F0F4C3;

}

.icon-color-lime-200 {
    color: #E6EE9C;

}

.icon-color-lime-300 {
    color: #DCE775;

}

.icon-color-lime-400 {
    color: #D4E157;

}

.icon-color-lime-500 {
    color: #CDDC39;

}

.icon-color-lime-600 {
    color: #C0CA33;

}

.icon-color-lime-700 {
    color: #AFB42B;

}

.icon-color-lime-800 {
    color: #9E9D24;

}

.icon-color-lime-900 {
    color: #827717;

}

.icon-color-lime-a100 {
    color: #F4FF81;

}

.icon-color-lime-a200 {
    color: #EEFF41;

}

.icon-color-lime-a400 {
    color: #C6FF00;

}

.icon-color-lime-a700 {
    color: #AEEA00;

}


.icon-color-yellow {
    color: #FFEB3B;

}

.icon-color-yellow-50 {
    color: #FFFDE7;

}

.icon-color-yellow-100 {
    color: #FFF9C4;

}

.icon-color-yellow-200 {
    color: #FFF59D;

}

.icon-color-yellow-300 {
    color: #FFF176;

}

.icon-color-yellow-400 {
    color: #FFEE58;

}

.icon-color-yellow-500 {
    color: #FFEB3B;

}

.icon-color-yellow-600 {
    color: #FDD835;

}

.icon-color-yellow-700 {
    color: #FBC02D;

}

.icon-color-yellow-800 {
    color: #F9A825;

}

.icon-color-yellow-900 {
    color: #F57F17;

}

.icon-color-yellow-a100 {
    color: #FFFF8D;

}

.icon-color-yellow-a200 {
    color: #FFFF00;

}

.icon-color-yellow-a400 {
    color: #FFEA00;

}

.icon-color-yellow-a700 {
    color: #FFD600;

}


.icon-color-amber {
    color: #FFC107;

}

.icon-color-amber-50 {
    color: #FFF8E1;

}

.icon-color-amber-100 {
    color: #FFECB3;

}

.icon-color-amber-200 {
    color: #FFE082;

}

.icon-color-amber-300 {
    color: #FFD54F;

}

.icon-color-amber-400 {
    color: #FFCA28;

}

.icon-color-amber-500 {
    color: #FFC107;

}

.icon-color-amber-600 {
    color: #FFB300;

}

.icon-color-amber-700 {
    color: #FFA000;

}

.icon-color-amber-800 {
    color: #FF8F00;

}

.icon-color-amber-900 {
    color: #FF6F00;

}

.icon-color-amber-a100 {
    color: #FFE57F;

}

.icon-color-amber-a200 {
    color: #FFD740;

}

.icon-color-amber-a400 {
    color: #FFC400;

}

.icon-color-amber-a700 {
    color: #FFAB00;

}


.icon-color-orange {
    color: #FF9800;

}

.icon-color-orange-50 {
    color: #FFF3E0;

}

.icon-color-orange-100 {
    color: #FFE0B2;

}

.icon-color-orange-200 {
    color: #FFCC80;

}

.icon-color-orange-300 {
    color: #FFB74D;

}

.icon-color-orange-400 {
    color: #FFA726;

}

.icon-color-orange-500 {
    color: #FF9800;

}

.icon-color-orange-600 {
    color: #FB8C00;

}

.icon-color-orange-700 {
    color: #F57C00;

}

.icon-color-orange-800 {
    color: #EF6C00;

}

.icon-color-orange-900 {
    color: #E65100;

}

.icon-color-orange-a100 {
    color: #FFD180;

}

.icon-color-orange-a200 {
    color: #FFAB40;

}

.icon-color-orange-a400 {
    color: #FF9100;

}

.icon-color-orange-a700 {
    color: #FF6D00;

}


.icon-color-deep-orange {
    color: #FF5722;

}

.icon-color-deep-orange-50 {
    color: #FBE9E7;

}

.icon-color-deep-orange-100 {
    color: #FFCCBC;

}

.icon-color-deep-orange-200 {
    color: #FFAB91;

}

.icon-color-deep-orange-300 {
    color: #FF8A65;

}

.icon-color-deep-orange-400 {
    color: #FF7043;

}

.icon-color-deep-orange-500 {
    color: #FF5722;

}

.icon-color-deep-orange-600 {
    color: #F4511E;

}

.icon-color-deep-orange-700 {
    color: #E64A19;

}

.icon-color-deep-orange-800 {
    color: #D84315;

}

.icon-color-deep-orange-900 {
    color: #BF360C;

}

.icon-color-deep-orange-a100 {
    color: #FF9E80;

}

.icon-color-deep-orange-a200 {
    color: #FF6E40;

}

.icon-color-deep-orange-a400 {
    color: #FF3D00;

}

.icon-color-deep-orange-a700 {
    color: #DD2C00;

}


.icon-color-brown {
    color: #795548;

}

.icon-color-brown-50 {
    color: #EFEBE9;

}

.icon-color-brown-100 {
    color: #D7CCC8;

}

.icon-color-brown-200 {
    color: #BCAAA4;

}

.icon-color-brown-300 {
    color: #A1887F;

}

.icon-color-brown-400 {
    color: #8D6E63;

}

.icon-color-brown-500 {
    color: #795548;

}

.icon-color-brown-600 {
    color: #6D4C41;

}

.icon-color-brown-700 {
    color: #5D4037;

}

.icon-color-brown-800 {
    color: #4E342E;

}

.icon-color-brown-900 {
    color: #3E2723;

}


.icon-color-grey {
    color: #9E9E9E;

}

.icon-color-grey-50 {
    color: #FAFAFA;

}

.icon-color-grey-100 {
    color: #F5F5F5;

}

.icon-color-grey-200 {
    color: #EEEEEE;

}

.icon-color-grey-300 {
    color: #E0E0E0;

}

.icon-color-grey-400 {
    color: #BDBDBD;

}

.icon-color-grey-500 {
    color: #9E9E9E;

}

.icon-color-grey-600 {
    color: #757575;

}

.icon-color-grey-700 {
    color: #616161;

}

.icon-color-grey-800 {
    color: #424242;

}

.icon-color-grey-900 {
    color: #212121;

}

.icon-color-blue-grey {
    color: #607D8B;

}

.icon-color-blue-grey-50 {
    color: #ECEFF1;

}

.icon-color-blue-grey-100 {
    color: #CFD8DC;

}

.icon-color-blue-grey-200 {
    color: #B0BEC5;

}

.icon-color-blue-grey-300 {
    color: #90A4AE;

}

.icon-color-blue-grey-400 {
    color: #78909C;

}

.icon-color-blue-grey-500 {
    color: #607D8B;

}

.icon-color-blue-grey-600 {
    color: #546E7A;

}

.icon-color-blue-grey-700 {
    color: #455A64;

}

.icon-color-blue-grey-800 {
    color: #37474F;

}

.icon-color-blue-grey-900 {
    color: #263238;

}

.icon-color-black {
    color: #000000;

}

.icon-color-white {
    color: #FFFFFF;

}