.pw-score-gauge {
    width: 16px;
    height: 21px;
    display: flex;

    z-index: 1000;
    line-height: 22px;
    box-sizing: border-box;
    vertical-align: middle;
    flex-direction: column;
    border: 2px solid #9c9c9c;
    padding: 1px;
    padding-bottom: 0px;

    .inc {
        background: #fff;
        flex-basis: 25%;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        border-bottom: 1px solid #fff;

    }

    &.score-1 {
        .one {
            background: red;
        }
    }

    &.score-2 {

        .one {
            background: gold;
        }

        .two {
            background: gold;
        }
    }

    &.score-3 {

        .one {
            background: greenyellow;
        }

        .two {
            background: greenyellow;
        }

        .three {
            background: greenyellow;
        }
    }

    &.score-4 {

        .one {
            background: #4CAF50;
        }

        .two {
            background: #4CAF50;
        }

        .three {
            background: #4CAF50;
        }

        .four {
            background: #4CAF50;
        }
    }

    &.in-pw-list {
        height: 16px;
        margin-left: 0;
        position: inherit;
        vertical-align: middle;
        width: 1.28571em;

        .inc {

            height: 2px;
            margin: 1px 1px 1px 3px;
            width: 11px;
        }
    }
}