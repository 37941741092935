.m-right-8 {
    margin-right: 8px;
}

.m-clear-all {
    margin: 0px;
}

.m-left-5 {
    margin-left: 5px;
}

.m-left-8 {
    margin-left: 8px;
}

.m-left-10 {
    margin-left: 10px;
}

.m-left-15px {
    margin-left: 15px;
}

.m-left-25px {
    margin-left: 25px;
}

.m-right-8 {
    margin-right: 8px;

}

.m-right-24 {
    margin-right: 24px;
}

.m-bottom-5 {
    margin-bottom: 5px;
}

.m-bottom-12 {
    margin-bottom: 12px;
}

.m-all-5 {
    margin: 5px;
}

.m-bottom-25 {
    margin-bottom: 25px;
}

.padding-top-10 {
    padding-top: 10px;
}


.padding-top-15 {
    padding-top: 15px;
}

.padding-top-25px,
.padding-top-25 {
    padding-top: 25px;
}

.padding-left-right-2px {
    padding-left: 2px;
    padding-right: 2px;
}

.padding-left-25px,
.padding-left-25 {
    padding-left: 25px;

}

.p-clear-all {
    padding: 0px;
}

.p-clear-right {
    padding-right: 0px;
}

.padding-5px {
    padding: 5px;
}

.padding-10px {
    padding: 10px;
}

.padding-20px {
    padding: 20px;
}

.padding-bottom-5px {
    padding-bottom: 5px;
}

.padding-bottom-10px,
.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15px,
.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-25px,
.padding-bottom-25 {
    padding-bottom: 25px;
}

.padding-top-bottom-5px {
    padding-top: 5px;
    padding-bottom: 5px;
}

.padding-top-bottom-15px {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-top-bottom-25px {
    padding-top: 25px;
    padding-bottom: 25px;
}

.left-padding-15px {
    padding-left: 15px;
}

.left-padding-25px {
    padding-left: 25px;
}

.left-right-padding-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.right-padding-15px {
    padding-right: 15px;
}

.right-padding-25px {
    padding-right: 25px;
}

.right-padding-50px {
    padding-right: 50px;
}

.right-padding-75px {
    padding-right: 75px;
}

.vertical-space-5px,
.vertical-space-5 {
    padding-top: 5px;
}

.vertical-space-10px,
.vertical-space-10 {
    padding-top: 10px;
}

.vertical-space-15px,
.vertical-space-15 {
    padding-top: 15px;
}

.vertical-space-25px,
.vertical-space-25 {
    padding-top: 25px;
}

.vertical-space-35px,
.vertical-space-35 {
    padding-top: 35px;
}

.vertical-space-50 {
    padding-top: 50px;
}