@media(max-width:768px) {

    .mat-drawer.settings-drawer-right-side-drawer-container,
    .mat-drawer.license-manager-right-side-drawer-container,
    .mat-drawer.label-list-right-side-drawer-container,
    .mat-drawer.password-list-right-side-drawer-container,
    .mat-drawer.teams-right-side-drawer-container,
    .mat-drawer.password-detail-right-side-drawer-container,
    .mat-drawer.billing-settings-right-side-drawer-container,
    .mat-drawer.account-settings-right-side-drawer-container,
    .mat-drawer.right-side-drawer-container {
        min-width: 300px;
        max-width: 350px;
    }
}

@media(min-width:768px) {

    .mat-drawer.settings-drawer-right-side-drawer-container,
    .mat-drawer.license-manager-right-side-drawer-container,
    .mat-drawer.label-list-right-side-drawer-container,
    .mat-drawer.password-list-right-side-drawer-container,
    .mat-drawer.teams-right-side-drawer-container,
    .mat-drawer.password-detail-right-side-drawer-container,
    .mat-drawer.billing-settings-right-side-drawer-container,
    .mat-drawer.account-settings-right-side-drawer-container,
    .mat-drawer.right-side-drawer-container {
        min-width: 500px;
        max-width: 800px;
    }
}

.drawer-sub-header {
    font-size: 16px;
}

.mat-toolbar.drawer-header {
    justify-content: space-between;
    display: flex;
    background-color: rgb(237, 242, 246);
    color: #001c53;
    font-size: 22px;
    height: 60px;
}

.authenticated-drawer-content {
    // 30 px for the header
    max-height: calc(100vh - 30px);
}

.right-drawer-container {
    padding: 20px;
    overflow-y: auto;
}

.right-drawer-container.header {
    text-align: center;
    font-size: 20px;
}

// .label-list-right-side-drawer-container,
// .label-list-right-side-drawer-container .mat-drawer-inner-container,
// .mat-drawer-inner-container {
//     width: 300px;
// }