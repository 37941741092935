.register-container {
 font-size: 18px;
     color: rgb(25,33,80);

}

.register-header {
    text-align: center;
    font-size: 60px;
    font-weight: 700;
    color: rgb(25,33,80);
}



.panel-grid.panel-has-style>.panel-row-style,.panel-grid.panel-no-style {
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: nowrap;
    -ms-justify-content: space-between;
    justify-content: space-between
}

.panel-grid .so-parallax {
    position: relative
}

.panel-grid .so-parallax>:not(.simpleParallax) {
    position: relative;
    z-index: 1
}

.panel-grid .so-parallax .simpleParallax {
    overflow: hidden
}

.panel-grid .so-parallax .simpleParallax,.panel-grid .so-parallax img[data-siteorigin-parallax] {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 0
}

.panel-background-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1
}

.panel-has-overlay .panel-cell-style,.panel-has-overlay .panel-row-style,.panel-has-overlay .panel-widget-style {
    overflow: hidden;
    position: relative;
    z-index: 1
}

.panel-layout.panel-is-rtl .panel-grid.panel-has-style>.panel-row-style,.panel-layout.panel-is-rtl .panel-grid.panel-no-style {
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

.panel-grid-cell {
    -ms-box-sizing: border-box;
    box-sizing: border-box
}

.panel-grid-cell .panel-cell-style {
    height: 100%
}

.panel-grid-cell .so-panel {
    zoom:1}

.panel-grid-cell .so-panel:before {
    content: "";
    display: block
}

.panel-grid-cell .so-panel:after {
    content: "";
    display: table;
    clear: both
}

.panel-grid-cell .panel-last-child {
    margin-bottom: 0
}

.panel-grid-cell .widget-title {
    margin-top: 0
}


// #pgc-w6699b996876ad-0-0 , #pgc-w6699b996876ad-0-1 , #pgc-w6699b996876ad-0-2 {
//                 // width: 33.3333%;
//                 // width: calc(33.3333% - ( 0.66666666666667 * 30px ) )
//             }

            #pl-w6699b996876ad .so-panel , #pl-w6699b996876ad .so-panel:last-of-type {
                margin-bottom: 0px
            }

            #pg-w6699b996876ad-0.panel-has-style > .panel-row-style, #pg-w6699b996876ad-0.panel-no-style {
                -webkit-align-items: flex-start;
                align-items: flex-start
            }

            #pgc-w6699b996876ad-0-0 , #pgc-w6699b996876ad-0-1 , #pgc-w6699b996876ad-0-2 {
                align-self: auto
            }

            @media (max-width: 780px) {
                #pg-w6699b996876ad-0.panel-no-style, #pg-w6699b996876ad-0.panel-has-style > .panel-row-style, #pg-w6699b996876ad-0 {
                    -webkit-flex-direction:column;
                    -ms-flex-direction: column;
                    flex-direction: column
                }

                #pg-w6699b996876ad-0 > .panel-grid-cell , #pg-w6699b996876ad-0 > .panel-row-style > .panel-grid-cell {
                    width: 100%;
                    margin-right: 0
                }

                #pgc-w6699b996876ad-0-0 , #pgc-w6699b996876ad-0-1 {
                    margin-bottom: 30px
                }

                #pl-w6699b996876ad .panel-grid-cell {
                    padding: 0
                }

                #pl-w6699b996876ad .panel-grid .panel-grid-cell-empty {
                    display: none
                }

                #pl-w6699b996876ad .panel-grid .panel-grid-cell-mobile-last {
                    margin-bottom: 0px
                }
            }

            /* Layout 2266 */
            #pgc-2266-0-0 {
                width: 100%;
                width: calc(100% - ( 0 * 30px ) )
            }

            #pl-2266 .so-panel , #pl-2266 .so-panel:last-of-type {
                margin-bottom: 0px
            }

            #pg-2266-0.panel-has-style > .panel-row-style, #pg-2266-0.panel-no-style {
                -webkit-align-items: flex-start;
                align-items: flex-start
            }

            @media (max-width: 780px) {
                #pg-2266-0.panel-no-style, #pg-2266-0.panel-has-style > .panel-row-style, #pg-2266-0 {
                    -webkit-flex-direction:column;
                    -ms-flex-direction: column;
                    flex-direction: column
                }

                #pg-2266-0 > .panel-grid-cell , #pg-2266-0 > .panel-row-style > .panel-grid-cell {
                    width: 100%;
                    margin-right: 0
                }

                #pl-2266 .panel-grid-cell {
                    padding: 0
                }

                #pl-2266 .panel-grid .panel-grid-cell-empty {
                    display: none
                }

                #pl-2266 .panel-grid .panel-grid-cell-mobile-last {
                    margin-bottom: 0px
                }
            }


#page #primary .mainwrap #topplans {
    margin: 30px 0px 0px;
        font-family: "Libre Franklin",Arial,Helvetica,sans-serif !important;

    
}

#page #primary .mainwrap #topplans .boxplans {
    background: #FFF;
    padding: 30px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #b6b6b6
}

#page #primary .mainwrap #topplans .boxplans h2 {
    margin: 0px 0px 5px;
    font-size: 30px;
    line-height: 36px
}

#page #primary .mainwrap #topplans .boxplans h2.price {
    font-weight: 800;
    font-size: 96px;
    line-height: 96px;
    margin: 20px 0px 12px
}

#page #primary .mainwrap #topplans .boxplans h2.price sup {
    font-size: 36px;
    font-weight: 600;
    line-height: 30px;
    top: -44px
}

#page #primary .mainwrap #topplans .boxplans p {
    margin: 0px;
    line-height: 24px
}

#page #primary .mainwrap #topplans .boxplans .so-widget-sow-button a {
    margin: 20px 0px 0px;
    background: #FFF;
    border-radius: 30px;
    border: 1px solid #081f56;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    padding: 12px 26px
}

#page #primary .mainwrap #topplans .boxplans .so-widget-sow-button a span {
    color: #081f56
}

#page #primary .mainwrap #topplans .boxplans.teams {
    border-top: 8px solid #6a81bc
}

#page #primary .mainwrap #topplans .boxplans.business {
    border-top: 8px solid #081f56
}

#page #primary .mainwrap #topplans .boxplans.enterprise {
    border-top: 8px solid #F26D3F
}            

#page #primary .mainwrap h1 {
    font-size: 60px;
    line-height: 72px;
    font-weight: 700;
    margin: 0px;
    color: #192150;
    clear: none
}

#page #primary .mainwrap h2 {
    font-size: 36px;
    line-height: 40px;
    font-weight: 700;
    margin: 0px 0px 25px;
    color: #192150;
    clear: none
}

#page #primary .mainwrap h2 span.orange {
    color: #F26D3F
}

#page #primary .mainwrap h2.center {
    text-align: center;
    display: block
}

#page #primary .mainwrap h3 {
    font-size: 24px;
    line-height: 38px;
    font-weight: 700;
    margin: 0px 0px 25px;
    color: #192150;
    clear: none
}

#page #primary .mainwrap h3.center {
    text-align: center;
    display: block
}

#page #primary .mainwrap .large h2 {
    font-size: 45px;
    line-height: 54px;
    font-weight: 700;
    margin: 0px 0px 25px;
    color: #192150;
    display: block
}

#page #primary .mainwrap .large h2.center {
    text-align: center
}

#page #primary .mainwrap h3 {
    color: #192150
}
