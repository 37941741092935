.font-size-12 {
    font-size: 12px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-14-important {
    font-size: 14px !important;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-32 {
    font-size: 32px;
}

.bold {
    font-weight: 500 !important;
}
.bold-strong {
    font-weight: 700;
}

.strikethrough {
    text-decoration: line-through;
}

.font-weight-400 {
    font-weight: 400;
}

.font-weight-500 {
    font-weight: 500;
}

.redacted {
    color: gray;
    // background-color: lightgray
}

.emphasis {
    font-style: italic;
}