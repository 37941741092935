.active-filter .menu-filter-button.mdc-button,
.active-filter {
    // background-color: #e0e0e0;
    background-color: #F26D3F;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.filter-info {
    padding: 5px 10px;
}

.filter-in-menu-button.mdc-button {
    padding: 0px !important;
    min-width: auto;
    height: auto !important;
}