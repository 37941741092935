.standard-content-container,
.report-background {
    height: 100%;
    padding-left: 5px;
    padding-right: 5px;
}

.not-production {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
}


.column-container-flex {
    // margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.justify-content-center {
    justify-content: center;
}

.flexbox {
    display: flex;
    flex-direction: column;
}

.flexbox>div {
    text-align: center;
    padding: 20px 0;
    margin: 5px;
}

.flexcolumn {
    display: flex;
    flex-direction: column;
}

.flexrow-no-justify {
    display: flex;
    flex-direction: row;
}

.flexrow {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
}


// @media(min-width:576px) {
//     .flexbox {
//         flex-flow: row wrap;
//     }

//     .flexbox>.left {
//         order: 1;
//         flex: 0.5;
//     }

//     .flexbox>.right {
//         order: 2;
//         flex: 0.5;
//     }

//     .flexbox>.center {
//         order: 3;
//         width: 100%;
//     }
// }

// @media(min-width:768px) {
//     .flexbox {
//         flex-flow: row nowrap;
//     }

//     .flexbox>div {
//         width: 33.33% !important;
//     }

//     .flexbox>.left {
//         order: 1;
//     }

//     .flexbox>.center {
//         order: 2;
//     }

//     .flexbox>.right {
//         order: 3;
//     }
// }


// @media(min-width:768px) {

//     .register-container,
//     .login-container {
//         display: contents;
//     }

//     .mobile-register-container,
//     .mobile-login-container {
//         display: none;
//     }
// }

// @media(max-width:768px) {

//     .register-container,
//     .login-container {
//         display: none;
//     }

//     .mobile-register-container,
//     .mobile-login-container {
//         display: contents;
//     }
// }