html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  background-color: #edf2f6; // passpack gray background
}

.body-container,
.settings-center-ceontainer,
.reports-center-container,
.label-detail-center-container,
.license-manager-center-container,
.billing-settings-center-container,
.account-settings-center-container,
.password-group-detail-center-container,
.teams-center-container,
.password-detail-center-container {
  background-color: #edf2f6;
}

.base-container {
  padding-left: 15px;
  padding-right: 15px;
}


.cdk-overlay-pane {
  div.mat-mdc-menu-content {
    padding: 0px;
  }
}

.superscript {
  font-size: 0.9em;
}


.full-page {
  // 30 px for the footer
  min-height: calc(100cqh - 30px);
}

// This is for left nav and others that are inside the outer container
.full-page-embedded {
  // 30 px for the footer
  // 60 px for the header
  min-height: calc(100cqh - 90px);
}

.main-body {
  min-height: calc(100cqh - 90px);
}

.main-body-noauth {
  min-height: calc(100cqh - 30px);
}

.no-wrap-text {
  white-space: nowrap;
}

.wrap-text {
  white-space: normal;
}

.height-100-percent {
  height: 100%;
}


@import "alignment.scss";
@import "buttons.scss";
@import "cdk-drag.scss";
@import "colors.scss";
@import "drawers.scss";
@import "filters.scss";
@import "fonts.scss";
@import "forms.scss";
@import "header.scss";
@import "icons.scss";
@import "icon-colors.scss";
@import "icon-sizes.scss";
@import "image.scss";
@import "labels.scss";
@import "layout-details.scss";
@import "left-nav.scss";
@import "license-manager.scss";
@import "link-styles.scss";
@import "mfa.scss";
@import "menus.scss";
@import "page-header.scss";
@import "page-layouts.scss";
@import "passwords.scss";
@import "passwordScore.scss";
@import "settings.scss";
@import "spacing.scss";
@import "stay-tuned.scss";
@import "tables.scss";
@import "widths.scss";