.mat-icon.action-icon,
.mat-icon.quick-copy-icon {
    font-size: 22px;
    height: 22px;
    width: 22px;
    padding-left: 0px;
    padding-right: 0px;
}

.mat-icon.quick-copy-icon-large {
    font-size: 32px;
    height: 32px;
    width: 32px;
    padding-left: 0px;
    padding-right: 0px;
}

.mat-icon.no-icon-overflow {
    overflow: visible;
}