.inner-page-header-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    margin-bottom: 20px;
    padding: 20px 48px;
    border-bottom: 1px solid rgb(203, 214, 226);
}

.inner-page-header__inner {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-wrap: wrap;
    min-height: 32px;
}

.inner-page-header:not(.inner-page-header--width-constrained) .inner-page-header__title {
    // display: flex;
    flex-basis: 0;
    flex-grow: 1;
    align-items: center;
}

.inner-page-header__title {
    max-width: 100%;
}


.inner-page-header .inner-page-header__title .inner-page-header__heading {
    margin-bottom: 0;
    max-width: 100%;
    padding-right: 28px;
}

.page-header__title h1 {
    // padding-bottom: 16px;
    margin-top: 0;
    font-size: 20px;
    margin: 0;
}

.page-header__title h2 {
    // margin-bottom: 16px;
    padding-top: 16px;
    margin-top: 0;
    font-size: 16px;
    margin: 0;
}

.page-header__title h1,
h2 {
    font-family: var(--uicomponents-font-family, "Lexend Deca", Helvetica, Arial, sans-serif);
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
    line-height: normal;
}


.selection-filters {}

.selection-filter {
    align-items: center;
    display: flex;
    // margin-right: 30px;
    // white-space: nowrap;

    &:last-child {
        margin-right: 0px;
    }
}