.width-250px {
    width: 250px;
}

.width-300px,
.width-300 {
    width: 300px;
}

.max-width-500 {
    max-width: 500px;
}

.width-500 {
    width: 500px;
}

.min-width-32 {
    min-width: 32px;
}

.mat-mdc-form-field.min-width-250,
.min-width-250 {
    min-width: 250px;
}

.mat-mdc-form-field.min-width-300,
.min-width-300 {
    min-width: 300px;
}


// .min-width-200 {
//     min-width: 200px !important;
// }

.min-width-300 {
    min-width: 300px !important;
}

.min-width-400 {
    min-width: 400px !important;
}

.width-45-percent {
    width: 45% !important;
}

.width-50-percent {
    width: 50% !important;
}

.width-70-percent {
    width: 70%;
}

.width-90-percent {
    width: 90%;
}

.width-100-percent {
    width: 100%;
}



.width-remaining {
    float: none;
    overflow: hidden;
}

.width-reset {
    width: auto !important;
}

.svg-icon-for-button {
    height: 24px;
    width: 24px;
}