.centered {
    text-align: center;
}

.centered-flex {
    justify-content: center;
}

.space-between {
    justify-content: space-between;
}

.centered-div,
.centered-icon {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.centered-div-margin-only {
    margin-left: auto;
    margin-right: auto;

}

.centered-div2 {
    align-items: center;
}

.display-flex {
    display: flex;
}

.display-flex-with-wrap {
    display: flex;
    flex-wrap: wrap;
}

.display-block {
    display: block;
}

.inline-flex {
    display: inline-flex;
}

.display-initial {
    display: initial;
}

.mat-mdc-checkbox label {
    display: inline-flex;
    margin: 0px;
}

.display-vertical-middle {
    display: flex;
    align-items: center;
    vertical-align: -webkit-baseline-middle;
}

.display-vertical-top {
    display: flex;
    align-items: start;
    vertical-align: top;
}

.display-vertical-bottom {
    display: flex;
    align-items: end;
    vertical-align: bottom;
}

.display-vertical-middle-centered {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: -webkit-baseline-middle;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.float-clear {
    clear: both;
}

.text-align-left {
    text-align: left;
}

.vertical-align-middle {
    vertical-align: -webkit-baseline-middle;
    justify-content: center;
}