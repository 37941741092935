.icon-size-150 {
    font-size: 150px;
    height: 150px;
    width: 150px;
}


.icon-size-22 {
    font-size: 22px !important;
    height: 22px !important;
    width: 22px !important;
}

.icon-size-26 {
    font-size: 26px !important;
    height: 26px !important;
    width: 26px !important;
}

.icon-size-30 {
    font-size: 30px;
    height: 30px;
    width: 30px;
}

.icon-size-20 {
    font-size: 20px;
    height: 20px;
    width: 20px;
}

.icon-size-32 {
    font-size: 32px;
    height: 32px;
    width: 32px;
}

.mat-icon.icon-size-12 {
    font-size: 12px;
    height: 12px;
    width: 12px;
}

.mat-icon.icon-size-14 {
    font-size: 14px;
    height: 14px;
    width: 14px;
}

.mat-icon.icon-size-16 {
    font-size: 16px;
    height: 16px;
    width: 16px;
}

.mat-icon.icon-size-18 {
    font-size: 18px;
    height: 18px;
    width: 18px;
}

.mat-icon.icon-size-24 {
    font-size: 24px;
    height: 24px;
    width: 24px;
}

.mat-icon.icon-size-32 {
    font-size: 32px;
    height: 32px;
    width: 32px;
}

.mat-icon.icon-size-48 {
    font-size: 48px;
    height: 48px;
    width: 48px;
}

.mat-icon.icon-size-150 {
    font-size: 150px;
    height: 150px;
    width: 150px;
}