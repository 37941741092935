.add-api-key-wizard,
.add-mfa-wizard {
    border: 1px solid !important;
    margin-top: 15px;
    margin-bottom: 15px;
    padding: 10px;
}

.secret-key-heading {
    font-size: 16px;
    font-weight: bold;
}

.secret-key {
    padding-top: 5px;
    font-size: 14px;
    font-weight: bold;
}


.step,
.purpose {
    font-size: 14px;
}