.team-side-component-title,
.password-side-component-title {

    text-align: left;
    font-size: 18px;
    // padding-bottom: 10px;
    color: #516f90;
}




.password-field-value,
.generated-password {
    text-wrap: wrap;
    overflow: auto;
    font-family: 'Courier New', Courier, monospace;
}

.password-column-list {
    // height: 100px;
    // -webkit-column-count: 3;
    // -moz-column-count: 3;
    // column-count: 3;
    list-style-type: disc;
}

.password-column-list li {
    // display: table;
    padding-bottom: 10px;
    margin-right: 30px;
}


.password-detail-name-cell,
.password-name-cell {
    min-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
}


.password-url-cell {
    min-width: 80px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.password-table {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.initial-size-password-button,
.password-button {
    width: initial;
}

.password-input-field {
    font-size: 14px !important;
    border: 0px;
}



.input-edit {
    background-color: powderblue;
}

.password-content-item {}

.password-content-item .password-content-label {
    display: flex;
    // padding-top: 10px;
    // padding-bottom: 10px;
    justify-content: space-between;
    width: 100%;
    flex-direction: row
}

.password-content-item .password-content-label .field-name {
    display: flex;
    flex-direction: row;
    padding-bottom: 8px;
    font-size: 16px;
    font-weight: 500;
}

.password-content-item .password-content-label .key-value-wrapper .title {
    flex-basis: 150px;
    font-weight: 500;
    font-size: 16px;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0
}

.password-content-item .password-content-label .key-value-wrapper .value-wrapper {
    white-space: pre-line;
    display: flex;
    flex-direction: column
}

.password-content-item .password-content-label .actions {
    display: flex;
    padding-left: 20px;

}