.red {
    color: #ff0000;
}

.green {
    color: rgb(45, 211, 111) !important
}

.gray {
    color: #e1e1e1;
}

.gold {
    color: #daa520;
}

.amber {
    color: #FFC107;
}

.background-highlight-color {
    background-color: whitesmoke;

}

.inherit-color,
.inherit-color a,
.inherit-color a:hover {
    color: inherit;
}

.passpack-light-background {
    background-color: rgb(237, 242, 246);

}

.passpack-background {
    background-color: #001c53;
}

.initial-color-background {
    background-color: rgb(237, 242, 246);
}



.dark-text a,
.dark-text {
    color: #33475b;
}

.white-background,
.white-background.white-background {
    background-color: #fff;
}

.white-backgrounded-field .mdc-text-field .mdc-notched,
.white-backgrounded-field .mdc-text-field--outlined .mdc-notched-outline {
    z-index: 0
}

.mdc-text-field--filled:not(.mdc-text-field--disabled),
.white-backgrounded-field .mdc-notched__notch,
.white-backgrounded-field .mdc-notched__leading,
.white-backgrounded-field .mdc-notched__trailing,
.white-backgrounded-field .mdc-notched-outline__notch,
.white-backgrounded-field .mdc-notched-outline__leading,
.white-backgrounded-field .mdc-notched-outline__trailing {
    background-color: white !important;
}


.white-text {
    color: #fff;
}


.color-fill {


    &.red {
        background-color: #F44336 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.red-50 {
        background-color: #FFEBEE !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.red-100 {
        background-color: #FFCDD2 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.red-200 {
        background-color: #EF9A9A !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.red-300 {
        background-color: #E57373 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.red-400 {
        background-color: #EF5350 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.red-500 {
        background-color: #F44336 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.red-600 {
        background-color: #E53935 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.red-700 {
        background-color: #D32F2F !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.red-800 {
        background-color: #C62828 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.red-900 {
        background-color: #B71C1C !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.red-a100 {
        background-color: #FF8A80 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.red-a200 {
        background-color: #FF5252 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.red-a400 {
        background-color: #FF1744 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.red-a700 {
        background-color: #D50000 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink {
        background-color: #E91E63 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink-50 {
        background-color: #FCE4EC !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.pink-100 {
        background-color: #F8BBD0 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.pink-200 {
        background-color: #F48FB1 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink-300 {
        background-color: #F06292 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink-400 {
        background-color: #EC407A !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink-500 {
        background-color: #E91E63 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink-600 {
        background-color: #D81B60 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink-700 {
        background-color: #C2185B !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink-800 {
        background-color: #AD1457 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink-900 {
        --mdc-chip-label-text-color: #fff;
        color: #fff;
        background-color: #880E4F !important;

    }

    &.pink-a100 {
        background-color: #FF80AB !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink-a200 {
        background-color: #FF4081 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink-a400 {
        background-color: #F50057 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.pink-a700 {
        background-color: #C51162 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple {
        background-color: #9C27B0 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-50 {
        background-color: #F3E5F5 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.purple-100 {
        background-color: #E1BEE7 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.purple-200 {
        background-color: #CE93D8 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-300 {
        background-color: #BA68C8 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-400 {
        background-color: #AB47BC !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-500 {
        background-color: #9C27B0 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-600 {
        background-color: #8E24AA !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-700 {
        background-color: #7B1FA2 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-800 {
        background-color: #6A1B9A !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-900 {
        background-color: #4A148C !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-a100 {
        background-color: #EA80FC !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-a200 {
        background-color: #E040FB !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-a400 {
        background-color: #D500F9 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.purple-a700 {
        background-color: #AA00FF !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple {
        background-color: #673AB7 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-50 {
        background-color: #EDE7F6 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.deep-purple-100 {
        background-color: #D1C4E9 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.deep-purple-200 {
        background-color: #B39DDB !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-300 {
        background-color: #9575CD !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-400 {
        background-color: #7E57C2 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-500 {
        background-color: #673AB7 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-600 {
        background-color: #5E35B1 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-700 {
        background-color: #512DA8 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-800 {
        background-color: #4527A0 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-900 {
        background-color: #311B92 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-a100 {
        background-color: #B388FF !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-a200 {
        background-color: #7C4DFF !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-a400 {
        background-color: #651FFF !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-purple-a700 {
        background-color: #6200EA !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }


    &.indigo {
        background-color: #3F51B5 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-50 {
        background-color: #E8EAF6 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.indigo-100 {
        background-color: #C5CAE9 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.indigo-200 {
        background-color: #9FA8DA !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-300 {
        background-color: #7986CB !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-400 {
        background-color: #5C6BC0 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-500 {
        background-color: #3F51B5 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-600 {
        background-color: #3949AB !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-700 {
        background-color: #303F9F !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-800 {
        background-color: #283593 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-900 {
        background-color: #1A237E !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-a100 {
        background-color: #8C9EFF !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-a200 {
        background-color: #536DFE !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-a400 {
        background-color: #3D5AFE !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.indigo-a700 {
        background-color: #304FFE !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }


    &.blue {
        background-color: #2196F3 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-50 {
        background-color: #E3F2FD !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.blue-100 {
        background-color: #BBDEFB !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.blue-200 {
        background-color: #90CAF9 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.blue-300 {
        background-color: #64B5F6 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-400 {
        background-color: #42A5F5 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-500 {
        background-color: #2196F3 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-600 {
        background-color: #1E88E5 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-700 {
        background-color: #1976D2 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-800 {
        background-color: #1565C0 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-900 {
        background-color: #0D47A1 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-a100 {
        background-color: #82B1FF !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-a200 {
        background-color: #448AFF !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-a400 {
        background-color: #2979FF !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-a700 {
        background-color: #2962FF !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-blue {
        background-color: #03A9F4 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-blue-50 {
        background-color: #E1F5FE !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.light-blue-100 {
        background-color: #B3E5FC !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.light-blue-200 {
        background-color: #81D4FA !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.light-blue-300 {
        background-color: #4FC3F7 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-blue-400 {
        background-color: #29B6F6 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-blue-500 {
        background-color: #03A9F4 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-blue-600 {
        background-color: #039BE5 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-blue-700 {
        background-color: #0288D1 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-blue-800 {
        background-color: #0277BD !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-blue-900 {
        background-color: #01579B !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-blue-a100 {
        background-color: #80D8FF !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.light-blue-a200 {
        background-color: #40C4FF !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-blue-a400 {
        background-color: #00B0FF !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-blue-a700 {
        background-color: #0091EA !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.cyan {
        background-color: #00BCD4 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.cyan-50 {
        background-color: #E0F7FA !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.cyan-100 {
        background-color: #B2EBF2 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.cyan-200 {
        background-color: #80DEEA !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.cyan-300 {
        background-color: #4DD0E1 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.cyan-400 {
        background-color: #26C6DA !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.cyan-500 {
        background-color: #00BCD4 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.cyan-600 {
        background-color: #00ACC1 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.cyan-700 {
        background-color: #0097A7 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.cyan-800 {
        background-color: #00838F !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.cyan-900 {
        background-color: #006064 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.cyan-a100 {
        background-color: #84FFFF !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.cyan-a200 {
        background-color: #18FFFF !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.cyan-a400 {
        background-color: #00E5FF !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.cyan-a700 {
        background-color: #00B8D4 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }


    &.teal {
        background-color: #009688 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.teal-50 {
        background-color: #E0F2F1 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.teal-100 {
        background-color: #B2DFDB !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.teal-200 {
        background-color: #80CBC4 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.teal-300 {
        background-color: #4DB6AC !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.teal-400 {
        background-color: #26A69A !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.teal-500 {
        background-color: #009688 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.teal-600 {
        background-color: #00897B !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.teal-700 {
        background-color: #00796B !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.teal-800 {
        background-color: #00695C !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.teal-900 {
        background-color: #004D40 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.teal-a100 {
        background-color: #A7FFEB !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.teal-a200 {
        background-color: #64FFDA !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.teal-a400 {
        background-color: #1DE9B6 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.teal-a700 {
        background-color: #00BFA5 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }


    &.green {
        background-color: #4CAF50 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.green-50 {
        background-color: #E8F5E9 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.green-100 {
        background-color: #C8E6C9 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.green-200 {
        background-color: #A5D6A7 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.green-300 {
        background-color: #81C784 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.green-400 {
        background-color: #66BB6A !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.green-500 {
        background-color: #4CAF50 !important;

        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.green-600 {
        background-color: #43A047 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.green-700 {
        background-color: #388E3C !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.green-800 {
        background-color: #2E7D32 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.green-900 {
        background-color: #1B5E20 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.green-a100 {
        background-color: #B9F6CA !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.green-a200 {
        background-color: #69F0AE !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.green-a400 {
        background-color: #00E676 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.green-a700 {
        background-color: #00C853 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }


    &.light-green {
        background-color: #8BC34A !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-green-50 {
        background-color: #F1F8E9 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.light-green-100 {
        background-color: #DCEDC8 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.light-green-200 {
        background-color: #C5E1A5 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.light-green-300 {
        background-color: #AED581 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.light-green-400 {
        background-color: #9CCC65 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-green-500 {
        background-color: #8BC34A !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-green-600 {
        background-color: #7CB342 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-green-700 {
        background-color: #689F38 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-green-800 {
        background-color: #558B2F !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-green-900 {
        background-color: #33691E !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.light-green-a100 {
        background-color: #CCFF90 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.light-green-a200 {
        background-color: #B2FF59 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.light-green-a400 {
        background-color: #76FF03 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.light-green-a700 {
        background-color: #64DD17 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }


    &.lime {
        background-color: #CDDC39 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.lime-50 {
        background-color: #F9FBE7 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.lime-100 {
        background-color: #F0F4C3 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.lime-200 {
        background-color: #E6EE9C !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.lime-300 {
        background-color: #DCE775 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.lime-400 {
        background-color: #D4E157 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.lime-500 {
        background-color: #CDDC39 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.lime-600 {
        background-color: #C0CA33 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.lime-700 {
        background-color: #AFB42B !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.lime-800 {
        background-color: #9E9D24 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.lime-900 {
        background-color: #827717 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.lime-a100 {
        background-color: #F4FF81 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.lime-a200 {
        background-color: #EEFF41 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.lime-a400 {
        background-color: #C6FF00 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.lime-a700 {
        background-color: #AEEA00 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }


    &.yellow {
        background-color: #FFEB3B !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.yellow-50 {
        background-color: #FFFDE7 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.yellow-100 {
        background-color: #FFF9C4 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.yellow-200 {
        background-color: #FFF59D !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.yellow-300 {
        background-color: #FFF176 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.yellow-400 {
        background-color: #FFEE58 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.yellow-500 {
        background-color: #FFEB3B !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.yellow-600 {
        background-color: #FDD835 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.yellow-700 {
        background-color: #FBC02D !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.yellow-800 {
        background-color: #F9A825 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.yellow-900 {
        background-color: #F57F17 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.yellow-a100 {
        background-color: #FFFF8D !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.yellow-a200 {
        background-color: #FFFF00 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.yellow-a400 {
        background-color: #FFEA00 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.yellow-a700 {
        background-color: #FFD600 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }


    &.amber {
        background-color: #FFC107 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.amber-50 {
        background-color: #FFF8E1 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.amber-100 {
        background-color: #FFECB3 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.amber-200 {
        background-color: #FFE082 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.amber-300 {
        background-color: #FFD54F !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.amber-400 {
        background-color: #FFCA28 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.amber-500 {
        background-color: #FFC107 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.amber-600 {
        background-color: #FFB300 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.amber-700 {
        background-color: #FFA000 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.amber-800 {
        background-color: #FF8F00 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.amber-900 {
        background-color: #FF6F00 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.amber-a100 {
        background-color: #FFE57F !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.amber-a200 {
        background-color: #FFD740 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.amber-a400 {
        background-color: #FFC400 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.amber-a700 {
        background-color: #FFAB00 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }


    &.orange {
        background-color: #FF9800 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.orange-50 {
        background-color: #FFF3E0 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.orange-100 {
        background-color: #FFE0B2 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.orange-200 {
        background-color: #FFCC80 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.orange-300 {
        background-color: #FFB74D !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.orange-400 {
        background-color: #FFA726 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.orange-500 {
        background-color: #FF9800 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.orange-600 {
        background-color: #FB8C00 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.orange-700 {
        background-color: #F57C00 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.orange-800 {
        background-color: #EF6C00 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.orange-900 {
        background-color: #E65100 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.orange-a100 {
        background-color: #FFD180 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.orange-a200 {
        background-color: #FFAB40 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.orange-a400 {
        background-color: #FF9100 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.orange-a700 {
        background-color: #FF6D00 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }


    &.deep-orange {
        background-color: #FF5722 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-orange-50 {
        background-color: #FBE9E7 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.deep-orange-100 {
        background-color: #FFCCBC !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.deep-orange-200 {
        background-color: #FFAB91 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.deep-orange-300 {
        background-color: #FF8A65 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-orange-400 {
        background-color: #FF7043 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-orange-500 {
        background-color: #FF5722 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-orange-600 {
        background-color: #F4511E !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-orange-700 {
        background-color: #E64A19 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-orange-800 {
        background-color: #D84315 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-orange-900 {
        background-color: #BF360C !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-orange-a100 {
        background-color: #FF9E80 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.deep-orange-a200 {
        background-color: #FF6E40 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-orange-a400 {
        background-color: #FF3D00 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.deep-orange-a700 {
        background-color: #DD2C00 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }


    &.brown {
        background-color: #795548 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.brown-50 {
        background-color: #EFEBE9 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.brown-100 {
        background-color: #D7CCC8 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.brown-200 {
        background-color: #BCAAA4 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.brown-300 {
        background-color: #A1887F !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.brown-400 {
        background-color: #8D6E63 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.brown-500 {
        background-color: #795548 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.brown-600 {
        background-color: #6D4C41 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.brown-700 {
        background-color: #5D4037 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.brown-800 {
        background-color: #4E342E !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.brown-900 {
        background-color: #3E2723 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }


    &.grey {
        background-color: #9E9E9E !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.grey-50 {
        background-color: #FAFAFA !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.grey-100 {
        background-color: #F5F5F5 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.grey-200 {
        background-color: #EEEEEE !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.grey-300 {
        background-color: #E0E0E0 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.grey-400 {
        background-color: #BDBDBD !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.grey-500 {
        background-color: #9E9E9E !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.grey-600 {
        background-color: #757575 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.grey-700 {
        background-color: #616161 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.grey-800 {
        background-color: #424242 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.grey-900 {
        background-color: #212121 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-grey {
        background-color: #607D8B !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-grey-50 {
        background-color: #ECEFF1 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.blue-grey-100 {
        background-color: #CFD8DC !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }

    &.blue-grey-200 {
        background-color: #B0BEC5 !important;
        --mdc-chip-label-text-color: initial;
        color: initial;
    }

    &.blue-grey-300 {
        background-color: #90A4AE !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-grey-400 {
        background-color: #78909C !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-grey-500 {
        background-color: #607D8B !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-grey-600 {
        background-color: #546E7A !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-grey-700 {
        background-color: #455A64 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-grey-800 {
        background-color: #37474F !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.blue-grey-900 {
        background-color: #263238 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.black {
        background-color: #000000 !important;
        --mdc-chip-label-text-color: #fff;
        color: #fff;
    }

    &.white {
        background-color: #FFFFFF !important;
        --mdc-chip-label-text-color: initial;
        color: initial;

    }
}