.license-manager-table {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.license-role-column {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 3px;
}

.license-role-box {
    border-color: rgb(120, 57, 238);
    background-color: rgb(244, 243, 255);
    color: rgb(120, 57, 238);
    font-size: 9px;
    line-height: 10px;
    font-weight: 500;
    letter-spacing: 0;
    border: 1px solid;
    border-radius: 3px;
    padding: 5px 6px;
    white-space: nowrap;
    text-transform: uppercase;
}

.license-mfa-icons {
    white-space: nowrap;
}


.table-header-with-filter {
    display: flex;
    flex-direction: column;
}

.quick-stat-column {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.quick-stat-column .title {
    font-size: 20px;
    font-weight: 500;
}

.quick-stat-column .value {
    font-size: 16px;
    font-weight: 500;
}