.left-nav {
    float: left;
    background-color: #b11717;
}

.settings-left-nav {
    border-right: 1px solid rgb(203, 214, 226) !important;
    height: 100%;
    padding-top: 20px;
    min-width: 200px;
    ;
}

.left-nav-header {
    border-bottom: 1px solid rgb(203, 214, 226) !important;
}

.left-nav-header h1 {
    font-size: 22px;
    padding-bottom: 10px;
}

.left-nav-section {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.left-nav-section .heading {
    font-size: 18px;
    font-weight: bold;
    padding-bottom: 10px;
}

.left-nav-section .section {
    font-size: 14px;
    padding: 5px 0;
}